export const rules = {
  times: [{ required: true, message: '此为必填项', trigger: 'blur' }],
  publishType: [{ required: true, message: '此为必填项', trigger: 'change' }],
  startTime: [{ required: true, message: '此为必填项', trigger: 'change' }],
  periodStartTime: [{ required: true, message: '此为必填项', trigger: 'change' }],
  endTime: [{ required: true, message: '此为必填项', trigger: 'change' }],
  recursiveDeviceStr: [{ required: true, message: '此为必填项', trigger: 'change' }],
  recursiveDeviceDays: [{ required: true, message: '此为必填项', trigger: 'blur' }],
  recursiveDevice: [{ required: true, message: '此为必填项', trigger: 'change' }],
  activeDate: [{ required: true, message: '此为必填项', trigger: 'change' }],
  loopModeType: [{ required: true, message: '此为必填项', trigger: 'change' }],
  periodActivityTitle: [{ required: true, message: '此为必填项', trigger: 'blur' }],
  activityTitle: [{ required: true, message: '此为必填项', trigger: 'blur' }],
  activityImage: [{ required: true, message: '此为必填项', trigger: ['change', 'click'] }],
  activityTypeId: [{ required: true, message: '此为必填项', trigger: 'change' }],
  address: [{ required: true, message: '此为必填项', trigger: 'change' }],
  detailAddress: [{ required: true, message: '此为必填项', trigger: 'change' }],
  activityDetail: [{ required: true, message: '此为必填项', trigger: 'change' }],
  projectIdList: [{ required: true, message: '此为必填项', trigger: 'change' }],
  userRestriction: [{ required: true, message: '此为必填项', trigger: 'change' }],
  enterpriseTypeIdIdList: [{ required: true, message: '此为必填项', trigger: 'change' }],
  fileL: [{ required: true, message: '此为必填项', trigger: 'change' }],
  isApply: [{ required: true, message: '此为必填项', trigger: 'change' }],
  participationWay: [{ required: true, message: '此为必填项', trigger: 'change' }],
  isNotification: [{ required: true, message: '此为必填项', trigger: 'change' }],
  notificationType: [{ required: true, message: '此为必填项', trigger: 'change' }],
  messagePushTime: [{ required: true, message: '此为必填项', trigger: 'change' }],
  isShare: [{ required: true, message: '此为必填项', trigger: 'change' }],
  isQuestionnaire: [{ required: true, message: '此为必填项', trigger: 'change' }],
  viewDetailAddress: [{ required: true, message: '此为必填项', trigger: 'change' }],
  questionnaireName: [{ required: true, message: '此为必填项', trigger: ['change', 'click'] }],
  fileName: [{ required: true, message: '此为必填项', trigger: 'change' }],
  isShowNeedNotRegister: [{ required: true, message: '此为必填项', trigger: 'change' }],
  personnelCeilingType: [{ required: true, message: '此为必填项', trigger: 'change' }],
  activityPeriod: [{ required: true, message: '此为必填项', trigger: 'change' }],
};

export const activeColumns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
  },
  {
    title: '活动时间',
    dataIndex: 'startTime',
    key: 'startTime',
    customRender: (text, row, index) => {
      return row.startTime +'至'+row.endTime;
    },
  },
  {
    title: '星期',
    dataIndex: 'weekStr',
    key: 'weekStr',
  },
  {
    title: '活动场地',
    dataIndex: 'placeName',
    key: 'placeName',
  },
  {
    title: '维护场次',
    dataIndex: 'index',
    key: 'index',
    customRender: (text, row, index) => {
      return row.status || '/';
    },
  },
  {
    title: '关联转态',
    dataIndex: 'status',
    key: 'status',
    customRender: (text, row, index) => {
      return row.status == 0 ? '正常' : row.status == 1 ? '当天无场次' : row.status == 2 ? '当前场次被占用' : '/';
    },
  },
];
